import React from "react"
import ContentLoader from "react-content-loader"

import "./style.css"

const MyLoader = (props) => (
  <div className="loader">
    <ContentLoader
      speed={2}
      width={350}
      height={363}
      viewBox="0 0 400 363"
      backgroundColor="#e6e6e6"
      foregroundColor="#f7f7f7"
      {...props}
    >
      <path d="M 0 173 v 173 h 300 V 0 H 0 v 173 z m 298 0 v 171 H 2 V 2 h 296 v 171 z" />
      <path d="M 8 16.9 c -0.7 1.3 -1 30.3 -1 87.3 c 0 75.6 0.2 85.6 1.6 87.6 l 1.5 2.2 h 139.8 c 116.7 0 140 -0.2 141 -1.4 c 0.8 -1 1 -25.1 0.9 -89.2 l -0.3 -87.9 l -141.2 -0.3 C 11.5 15 9.1 15 8 16.9 z M 12.6 210.6 c -1.2 1.2 -1.6 3.7 -1.6 10.5 c 0 14.5 -8.9 12.9 71.8 12.9 c 53 0 70.1 -0.3 71 -1.2 c 1.6 -1.6 1.6 -21 0 -22.6 c -0.9 -0.9 -17.9 -1.2 -70.4 -1.2 c -61.6 0 -69.4 0.2 -70.8 1.6 z M 13.2 242.6 c -1.8 1.3 -2.2 2.5 -2.2 7.4 c 0 9.7 -5.8 9 72.3 9 c 44.3 0 68.5 -0.4 69.8 -1 c 1.6 -0.9 1.9 -2.2 1.9 -8.1 c 0 -6.5 -0.1 -7 -2.6 -7.9 c -1.5 -0.6 -28.5 -1 -69.7 -1 c -59.3 0 -67.5 0.2 -69.5 1.6 z M 13.2 267.6 c -1.8 1.3 -2.2 2.5 -2.2 7.4 c 0 9.7 -5.8 8.9 72.2 9 c 45.5 0 68.5 -0.3 69.8 -1 c 1.7 -0.9 2 -2.1 2 -8.1 c 0 -6.5 -0.1 -7 -2.6 -7.9 c -1.5 -0.6 -28.5 -1 -69.7 -1 c -59.3 0 -67.5 0.2 -69.5 1.6 z M 13.2 292.6 c -2 1.4 -2.2 2.3 -2.2 11.4 c 0 9.1 0.2 10 2.2 11.5 c 2 1.3 10.1 1.5 70.2 1.5 c 43.6 0 68.5 -0.4 69.7 -1.1 c 1.7 -0.9 1.9 -2.2 1.9 -12 c 0 -10.8 0 -11 -2.6 -11.9 c -1.5 -0.6 -28.5 -1 -69.7 -1 c -59.3 0 -67.5 0.2 -69.5 1.6 z" />
    </ContentLoader>
    <ContentLoader
      speed={2}
      width={350}
      height={363}
      viewBox="0 0 400 363"
      backgroundColor="#e6e6e6"
      foregroundColor="#f7f7f7"
      {...props}
    >
      <path d="M 0 173 v 173 h 300 V 0 H 0 v 173 z m 298 0 v 171 H 2 V 2 h 296 v 171 z" />
      <path d="M 8 16.9 c -0.7 1.3 -1 30.3 -1 87.3 c 0 75.6 0.2 85.6 1.6 87.6 l 1.5 2.2 h 139.8 c 116.7 0 140 -0.2 141 -1.4 c 0.8 -1 1 -25.1 0.9 -89.2 l -0.3 -87.9 l -141.2 -0.3 C 11.5 15 9.1 15 8 16.9 z M 12.6 210.6 c -1.2 1.2 -1.6 3.7 -1.6 10.5 c 0 14.5 -8.9 12.9 71.8 12.9 c 53 0 70.1 -0.3 71 -1.2 c 1.6 -1.6 1.6 -21 0 -22.6 c -0.9 -0.9 -17.9 -1.2 -70.4 -1.2 c -61.6 0 -69.4 0.2 -70.8 1.6 z M 13.2 242.6 c -1.8 1.3 -2.2 2.5 -2.2 7.4 c 0 9.7 -5.8 9 72.3 9 c 44.3 0 68.5 -0.4 69.8 -1 c 1.6 -0.9 1.9 -2.2 1.9 -8.1 c 0 -6.5 -0.1 -7 -2.6 -7.9 c -1.5 -0.6 -28.5 -1 -69.7 -1 c -59.3 0 -67.5 0.2 -69.5 1.6 z M 13.2 267.6 c -1.8 1.3 -2.2 2.5 -2.2 7.4 c 0 9.7 -5.8 8.9 72.2 9 c 45.5 0 68.5 -0.3 69.8 -1 c 1.7 -0.9 2 -2.1 2 -8.1 c 0 -6.5 -0.1 -7 -2.6 -7.9 c -1.5 -0.6 -28.5 -1 -69.7 -1 c -59.3 0 -67.5 0.2 -69.5 1.6 z M 13.2 292.6 c -2 1.4 -2.2 2.3 -2.2 11.4 c 0 9.1 0.2 10 2.2 11.5 c 2 1.3 10.1 1.5 70.2 1.5 c 43.6 0 68.5 -0.4 69.7 -1.1 c 1.7 -0.9 1.9 -2.2 1.9 -12 c 0 -10.8 0 -11 -2.6 -11.9 c -1.5 -0.6 -28.5 -1 -69.7 -1 c -59.3 0 -67.5 0.2 -69.5 1.6 z" />
    </ContentLoader>
    <ContentLoader
      speed={2}
      width={350}
      height={363}
      viewBox="0 0 400 363"
      backgroundColor="#e6e6e6"
      foregroundColor="#f7f7f7"
      {...props}
    >
      <path d="M 0 173 v 173 h 300 V 0 H 0 v 173 z m 298 0 v 171 H 2 V 2 h 296 v 171 z" />
      <path d="M 8 16.9 c -0.7 1.3 -1 30.3 -1 87.3 c 0 75.6 0.2 85.6 1.6 87.6 l 1.5 2.2 h 139.8 c 116.7 0 140 -0.2 141 -1.4 c 0.8 -1 1 -25.1 0.9 -89.2 l -0.3 -87.9 l -141.2 -0.3 C 11.5 15 9.1 15 8 16.9 z M 12.6 210.6 c -1.2 1.2 -1.6 3.7 -1.6 10.5 c 0 14.5 -8.9 12.9 71.8 12.9 c 53 0 70.1 -0.3 71 -1.2 c 1.6 -1.6 1.6 -21 0 -22.6 c -0.9 -0.9 -17.9 -1.2 -70.4 -1.2 c -61.6 0 -69.4 0.2 -70.8 1.6 z M 13.2 242.6 c -1.8 1.3 -2.2 2.5 -2.2 7.4 c 0 9.7 -5.8 9 72.3 9 c 44.3 0 68.5 -0.4 69.8 -1 c 1.6 -0.9 1.9 -2.2 1.9 -8.1 c 0 -6.5 -0.1 -7 -2.6 -7.9 c -1.5 -0.6 -28.5 -1 -69.7 -1 c -59.3 0 -67.5 0.2 -69.5 1.6 z M 13.2 267.6 c -1.8 1.3 -2.2 2.5 -2.2 7.4 c 0 9.7 -5.8 8.9 72.2 9 c 45.5 0 68.5 -0.3 69.8 -1 c 1.7 -0.9 2 -2.1 2 -8.1 c 0 -6.5 -0.1 -7 -2.6 -7.9 c -1.5 -0.6 -28.5 -1 -69.7 -1 c -59.3 0 -67.5 0.2 -69.5 1.6 z M 13.2 292.6 c -2 1.4 -2.2 2.3 -2.2 11.4 c 0 9.1 0.2 10 2.2 11.5 c 2 1.3 10.1 1.5 70.2 1.5 c 43.6 0 68.5 -0.4 69.7 -1.1 c 1.7 -0.9 1.9 -2.2 1.9 -12 c 0 -10.8 0 -11 -2.6 -11.9 c -1.5 -0.6 -28.5 -1 -69.7 -1 c -59.3 0 -67.5 0.2 -69.5 1.6 z" />
    </ContentLoader>
    <ContentLoader
      speed={2}
      width={350}
      height={363}
      viewBox="0 0 400 363"
      backgroundColor="#e6e6e6"
      foregroundColor="#f7f7f7"
      {...props}
    >
      <path d="M 0 173 v 173 h 300 V 0 H 0 v 173 z m 298 0 v 171 H 2 V 2 h 296 v 171 z" />
      <path d="M 8 16.9 c -0.7 1.3 -1 30.3 -1 87.3 c 0 75.6 0.2 85.6 1.6 87.6 l 1.5 2.2 h 139.8 c 116.7 0 140 -0.2 141 -1.4 c 0.8 -1 1 -25.1 0.9 -89.2 l -0.3 -87.9 l -141.2 -0.3 C 11.5 15 9.1 15 8 16.9 z M 12.6 210.6 c -1.2 1.2 -1.6 3.7 -1.6 10.5 c 0 14.5 -8.9 12.9 71.8 12.9 c 53 0 70.1 -0.3 71 -1.2 c 1.6 -1.6 1.6 -21 0 -22.6 c -0.9 -0.9 -17.9 -1.2 -70.4 -1.2 c -61.6 0 -69.4 0.2 -70.8 1.6 z M 13.2 242.6 c -1.8 1.3 -2.2 2.5 -2.2 7.4 c 0 9.7 -5.8 9 72.3 9 c 44.3 0 68.5 -0.4 69.8 -1 c 1.6 -0.9 1.9 -2.2 1.9 -8.1 c 0 -6.5 -0.1 -7 -2.6 -7.9 c -1.5 -0.6 -28.5 -1 -69.7 -1 c -59.3 0 -67.5 0.2 -69.5 1.6 z M 13.2 267.6 c -1.8 1.3 -2.2 2.5 -2.2 7.4 c 0 9.7 -5.8 8.9 72.2 9 c 45.5 0 68.5 -0.3 69.8 -1 c 1.7 -0.9 2 -2.1 2 -8.1 c 0 -6.5 -0.1 -7 -2.6 -7.9 c -1.5 -0.6 -28.5 -1 -69.7 -1 c -59.3 0 -67.5 0.2 -69.5 1.6 z M 13.2 292.6 c -2 1.4 -2.2 2.3 -2.2 11.4 c 0 9.1 0.2 10 2.2 11.5 c 2 1.3 10.1 1.5 70.2 1.5 c 43.6 0 68.5 -0.4 69.7 -1.1 c 1.7 -0.9 1.9 -2.2 1.9 -12 c 0 -10.8 0 -11 -2.6 -11.9 c -1.5 -0.6 -28.5 -1 -69.7 -1 c -59.3 0 -67.5 0.2 -69.5 1.6 z" />
    </ContentLoader>
    <ContentLoader
      speed={2}
      width={350}
      height={363}
      viewBox="0 0 400 363"
      backgroundColor="#e6e6e6"
      foregroundColor="#f7f7f7"
      {...props}
    >
      <path d="M 0 173 v 173 h 300 V 0 H 0 v 173 z m 298 0 v 171 H 2 V 2 h 296 v 171 z" />
      <path d="M 8 16.9 c -0.7 1.3 -1 30.3 -1 87.3 c 0 75.6 0.2 85.6 1.6 87.6 l 1.5 2.2 h 139.8 c 116.7 0 140 -0.2 141 -1.4 c 0.8 -1 1 -25.1 0.9 -89.2 l -0.3 -87.9 l -141.2 -0.3 C 11.5 15 9.1 15 8 16.9 z M 12.6 210.6 c -1.2 1.2 -1.6 3.7 -1.6 10.5 c 0 14.5 -8.9 12.9 71.8 12.9 c 53 0 70.1 -0.3 71 -1.2 c 1.6 -1.6 1.6 -21 0 -22.6 c -0.9 -0.9 -17.9 -1.2 -70.4 -1.2 c -61.6 0 -69.4 0.2 -70.8 1.6 z M 13.2 242.6 c -1.8 1.3 -2.2 2.5 -2.2 7.4 c 0 9.7 -5.8 9 72.3 9 c 44.3 0 68.5 -0.4 69.8 -1 c 1.6 -0.9 1.9 -2.2 1.9 -8.1 c 0 -6.5 -0.1 -7 -2.6 -7.9 c -1.5 -0.6 -28.5 -1 -69.7 -1 c -59.3 0 -67.5 0.2 -69.5 1.6 z M 13.2 267.6 c -1.8 1.3 -2.2 2.5 -2.2 7.4 c 0 9.7 -5.8 8.9 72.2 9 c 45.5 0 68.5 -0.3 69.8 -1 c 1.7 -0.9 2 -2.1 2 -8.1 c 0 -6.5 -0.1 -7 -2.6 -7.9 c -1.5 -0.6 -28.5 -1 -69.7 -1 c -59.3 0 -67.5 0.2 -69.5 1.6 z M 13.2 292.6 c -2 1.4 -2.2 2.3 -2.2 11.4 c 0 9.1 0.2 10 2.2 11.5 c 2 1.3 10.1 1.5 70.2 1.5 c 43.6 0 68.5 -0.4 69.7 -1.1 c 1.7 -0.9 1.9 -2.2 1.9 -12 c 0 -10.8 0 -11 -2.6 -11.9 c -1.5 -0.6 -28.5 -1 -69.7 -1 c -59.3 0 -67.5 0.2 -69.5 1.6 z" />
    </ContentLoader>
    <ContentLoader
      speed={2}
      width={350}
      height={363}
      viewBox="0 0 400 363"
      backgroundColor="#e6e6e6"
      foregroundColor="#f7f7f7"
      {...props}
    >
      <path d="M 0 173 v 173 h 300 V 0 H 0 v 173 z m 298 0 v 171 H 2 V 2 h 296 v 171 z" />
      <path d="M 8 16.9 c -0.7 1.3 -1 30.3 -1 87.3 c 0 75.6 0.2 85.6 1.6 87.6 l 1.5 2.2 h 139.8 c 116.7 0 140 -0.2 141 -1.4 c 0.8 -1 1 -25.1 0.9 -89.2 l -0.3 -87.9 l -141.2 -0.3 C 11.5 15 9.1 15 8 16.9 z M 12.6 210.6 c -1.2 1.2 -1.6 3.7 -1.6 10.5 c 0 14.5 -8.9 12.9 71.8 12.9 c 53 0 70.1 -0.3 71 -1.2 c 1.6 -1.6 1.6 -21 0 -22.6 c -0.9 -0.9 -17.9 -1.2 -70.4 -1.2 c -61.6 0 -69.4 0.2 -70.8 1.6 z M 13.2 242.6 c -1.8 1.3 -2.2 2.5 -2.2 7.4 c 0 9.7 -5.8 9 72.3 9 c 44.3 0 68.5 -0.4 69.8 -1 c 1.6 -0.9 1.9 -2.2 1.9 -8.1 c 0 -6.5 -0.1 -7 -2.6 -7.9 c -1.5 -0.6 -28.5 -1 -69.7 -1 c -59.3 0 -67.5 0.2 -69.5 1.6 z M 13.2 267.6 c -1.8 1.3 -2.2 2.5 -2.2 7.4 c 0 9.7 -5.8 8.9 72.2 9 c 45.5 0 68.5 -0.3 69.8 -1 c 1.7 -0.9 2 -2.1 2 -8.1 c 0 -6.5 -0.1 -7 -2.6 -7.9 c -1.5 -0.6 -28.5 -1 -69.7 -1 c -59.3 0 -67.5 0.2 -69.5 1.6 z M 13.2 292.6 c -2 1.4 -2.2 2.3 -2.2 11.4 c 0 9.1 0.2 10 2.2 11.5 c 2 1.3 10.1 1.5 70.2 1.5 c 43.6 0 68.5 -0.4 69.7 -1.1 c 1.7 -0.9 1.9 -2.2 1.9 -12 c 0 -10.8 0 -11 -2.6 -11.9 c -1.5 -0.6 -28.5 -1 -69.7 -1 c -59.3 0 -67.5 0.2 -69.5 1.6 z" />
    </ContentLoader>
  </div>
)

export default MyLoader
